var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container wrapper pb-5"},[_c('h3',{staticClass:"text-left"},[_vm._v("Jeżeli masz jakieś pytania to skontaktuj się z nami")]),_c('ValidationObserver',{ref:"observer",attrs:{"tag":"form"}},[_c('form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"input-container mb-3 input-wrapper"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.messageTitle),expression:"messageTitle"}],staticClass:"form-input w-100 control",class:classes,attrs:{"type":"text","placeholder":"temat zgłoszenia"},domProps:{"value":(_vm.messageTitle)},on:{"input":function($event){if($event.target.composing){ return; }_vm.messageTitle=$event.target.value}}}),_c('span',{staticClass:"form-error-info text-center"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)])]),_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"input-container mb-3 input-wrapper"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.messageAuthor),expression:"messageAuthor"}],staticClass:"form-input w-100 control",class:classes,attrs:{"type":"text","placeholder":"imię i nazwisko"},domProps:{"value":(_vm.messageAuthor)},on:{"input":function($event){if($event.target.composing){ return; }_vm.messageAuthor=$event.target.value}}}),_c('span',{staticClass:"form-error-info text-center"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)])]),_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"input-container mb-0 input-wrapper"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.message),expression:"message"}],staticClass:"form-input w-100 control",class:classes,attrs:{"type":"text","name":"","placeholder":"treść zgłoszenia"},domProps:{"value":(_vm.message)},on:{"input":function($event){if($event.target.composing){ return; }_vm.message=$event.target.value}}}),_c('span',{staticClass:"form-error-info text-center"},[_vm._v(_vm._s(errors[0]))]),_c('button',{staticClass:"btn-black-full w-100 mt-3",attrs:{"type":"submit"},on:{"click":function($event){return _vm.submit()}}},[_vm._v("Wyślij")])]}}])})],1)])])])]),(_vm.isModalOpen)?_c('ModalTemplate',{attrs:{"title":_vm.modalTitle,"content":_vm.modalContent,"acceptButton":_vm.firstButton,"acceptButtonContent":_vm.firstButtonContent,"closeButton":_vm.secondButton,"closeButtonContent":_vm.secondButtonContent},on:{"close-modal":_vm.close}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }