<template>
  <div>
    <b-modal id="modal-status" @hide="closeModal" ref="modal-status" :title="title" hide-header hide-footer class="app-modal" header-class="text-center" dialog-class="modal-dialog-centered" content-class="modal-dialog-centered" closeheader="none" >
      <h2 class=" mb-4">{{title}}</h2>
      <p>{{content}}</p>
      <button class="btn-black-full w-100 mt-4 mb-1" v-if="acceptButton">{{acceptButtonContent}}</button>
      <button class="w-100 mt-1 mb-2" v-if="closeButton"
      :class="acceptButton === true ?  'btn-gray-border' : 'btn-black-full'" @click="closeModal">{{closeButtonContent}}</button>
    </b-modal>
  </div>
</template>
<script>
export default {
  name: 'ModalTemplate',
  props: {
    title: String,
    content: String,
    acceptButton: Boolean,
    acceptButtonContent: String,
    closeButton: Boolean,
    closeButtonContent: String
  },
  data () {
    return {
    }
  },
  methods: {
    closeModal () {
      this.$emit('close-modal', false)
      this.$refs['modal-status'].hide()
    }
  },
  mounted () {
    this.$refs['modal-status'].show()
  }
}
</script>
<style lang="scss" scoped>
</style>
