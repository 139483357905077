<template>
<div class="container wrapper pb-5">
  <h3 class="text-left">Jeżeli masz jakieś pytania to skontaktuj się z nami</h3>
    <ValidationObserver ref="observer" tag="form" >
  <form @submit.prevent="">
    <div class="row align-items-center">
      <div class="col-12">
        <div class="input-container mb-3 input-wrapper">
          <ValidationProvider rules="required" v-slot="{errors, classes}">
              <input type="text" class="form-input w-100 control"
                :class="classes"
                placeholder="temat zgłoszenia" v-model="messageTitle"/>
              <span class="form-error-info text-center">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
      </div>
    </div>
    <div class="row align-items-center">
      <div class="col-12">
        <div class="input-container mb-3 input-wrapper">
          <ValidationProvider rules="required" v-slot="{errors, classes}">
              <input type="text" class="form-input w-100 control"
              :class="classes"
              placeholder="imię i nazwisko" v-model="messageAuthor"/>
              <span class="form-error-info text-center">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
      </div>
    </div>
    <div class="row align-items-center">
      <div class="col-12">
        <div class="input-container mb-0 input-wrapper">
          <ValidationProvider rules="required" v-slot="{errors, classes}" >
            <textarea type="text" class="form-input w-100 control"
            :class="classes"
            name="" placeholder="treść zgłoszenia" v-model="message"
            ></textarea>
            <span class="form-error-info text-center">{{ errors[0] }}</span>
            <button type="submit" @click="submit()"  class="btn-black-full w-100 mt-3">Wyślij</button>
          </ValidationProvider>
        </div>
      </div>
    </div>
  </form>
    </ValidationObserver>
    <ModalTemplate v-if="isModalOpen"
    :title="modalTitle" :content="modalContent"
    :acceptButton="firstButton" :acceptButtonContent="firstButtonContent"
    :closeButton="secondButton" :closeButtonContent="secondButtonContent"
    @close-modal="close" />
</div>
</template>
<script>
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { required } from 'vee-validate/dist/rules'
import ModalTemplate from '../components/ModalTemplate'
extend('required', {
  ...required,
  message: 'Pole jest obowiązkowe'
})

export default {
  name: 'ContactForm',
  components: {
    ValidationProvider,
    ValidationObserver,
    ModalTemplate
  },
  data () {
    return {
      messageTitle: '',
      messageAuthor: '',
      message: '',
      isModalOpen: false,
      modalTitle: 'Dziękujemy',
      modalContent: 'Twoja wiadomość została wysłana. Wkróce skontaktujemy się z Tobą.',
      firstButton: false,
      firstButtonContent: '',
      secondButton: true,
      secondButtonContent: 'OK'
    }
  },
  methods: {
    async submit () {
      const valid = await this.$refs.observer.validate()
      if (valid === true) {
        this.sendMessage()
        this.isModalOpen = true
      }
    },
    close (value) {
      this.isModalOpen = false
    },
    sendMessage () {
      const getData = `subject=${this.messageTitle}&content=${this.message}&name=${this.messageAuthor}`
      this.$https('/contact', {
        method: 'POST',
        mode: 'no-cors',
        crossDomain: true,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: getData
      }).then(response => {
      })
        .catch(error => {
          console.log('', error)
        })
    }
  },
  watch: {

  }
}
</script>
<style lang="scss" scoped>
  .wrapper {
  padding-top: 85px;
}

.form-input {
  background-color: #fff;
}

  .control {
    background-color: transparent;
        &.invalid {
      border-color: #ff0000 !important;
    }

    &.valid {
      border-color: #000 !important;
    }
  }

</style>
