<template>
  <div>
     <TabsNotification :activeTab="activeTab" :tabs="tabs" :title="title">
      <template slot="tab-head-faq">
        FAQ
      </template>
      <template slot="tab-panel-faq">
        <Faq />
      </template>

      <template slot="tab-head-sendmessage">
        napisz do nas
      </template>
      <template slot="tab-panel-sendmessage">
        <ContactForm />
      </template>
    </TabsNotification>
  </div>
</template>
<script>
import TabsNotification from '../components/TabsNotification'
import Faq from '../components/Faq'
import ContactForm from '../components/ContactForm'
export default {
  name: 'Help',
  components: {
    TabsNotification,
    ContactForm,
    Faq
  },
  data () {
    return {
      title: 'Pomoc',
      messageTitle: '',
      messageAuthor: '',
      message: '',
      activeTab: 'faq',
      tabs: ['faq', 'sendmessage']
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
