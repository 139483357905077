<template>
  <div class="wrapper">
    <div class="container mb-3">
      <div class="top-heading-big">Najczęściej zadawane pytania</div>
    </div>
    <div class="faq-container" v-for="(faq, key) in faq" :key="key">
      <button class="faq-label" :class="{collapsed: !faq.isOpen}" type="button"
      @click="faq.isOpen = !faq.isOpen"
      >
          <div class="pr-3">{{faq.question}}</div>
          <div class="faq-label-icon"></div>
      </button>
      <div class="faq-label-content collapse" id="faq-1" style="" :class="{ show: faq.isOpen }">
          <p>{{faq.answers}}</p>
      </div>
  </div>
  </div>
</template>
<script>
export default {
  name: 'FAQ',
  data () {
    return {
      faq: [
        {
          question: 'Jak zmienić zdjęcie profilowe',
          answers:
            'Proszę rozwinąc menu po prawej stronie i wejść w dział "Moje konto". Pod "Edytuj swój profil" znajduje się opcja zmiany zdjęcia profilowego.',
          isOpen: false
        },
        {
          question: 'Jak odblokować zablokowanych użytkowników',
          answers:
            'Proszę rozwinąc menu po prawej stronie i wejść w dział "Moje konto". Pod "Zablokowani użytkownicy" znajduje się lista zablokowanych użytkowników i tam można odblokować użytkownika.',
          isOpen: false
        },
        {
          question: 'Nie chcę aby inni mieli dostęp do mojej biblioteki',
          answers:
            'Proszę rozwinąc menu po prawej stronie i wejść w dział "Moje konto". Pod "Ustawienia prywatności" znajduje się możliwość wyłączenia widoczności bibloteki.',
          isOpen: false
        },
        {
          question: 'Jak pożyczyć książkę?',
          answers:
            'Należy wejść do Moja biblioteka, odnaleźć książkę na półce i kliknąć przycisk "Pożycz". Aplikacja przeniesie nas do widoku "Pożyczasz książkę" gdzie można wybrać czytelika po wpisaniu imienia i nazwiska. Jeśli czytelnik nie jest zarejestrowany w Bookme można podać jego imię, nazwisko i termin oddania książki. Dane te są przechowywane tylko dla informacji pożyczającego i nie są przetwarzane w żaden sposób.',
          isOpen: false
        },
        {
          question: 'Czy mogę wyłączyć grywalizację?',
          answers:
            'Proszę rozwinąc menu po prawej stronie i wejść w dział "Moje konto". Pod "Grywalizacja" znajduje się opcja wyłączenia grywalizacji lub wyłączenia widocznośći naszej grywalizacji.',
          isOpen: false
        },
        {
          question: 'W jaki sposób mogę zaprosić znajomych do korzystania z aplikacji?',
          answers:
            'Należy kliknąć w ikonkę koperty na dolnym menu i przejść do sekcji zaproszenia. Tam należy wypełnić formularz podając Imię i adres e-mail.',
          isOpen: false
        }
      ]
    }
  }
}
</script>
<style lang="scss" scoped>
  .wrapper {
  padding-top: 100px;
  margin-bottom: 50px;
}

.collapse {
  transition: max-height 0.4s ease-in-out;
  display: block;
  position: relative;
  overflow: hidden;
  max-height: 0px;

  &.show {
    max-height: 2000px;
  }
}

</style>
